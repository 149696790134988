import { CustomFieldTypes } from "@/assets/constants/CustomFieldTypes";

export const STATUS_TYPES = [
  { value: "draft", text: "Drafted" },
  { value: "published", text: "Published" },
  { value: "closed", text: "Closed" },
  { value: "expired", text: "Expired" },
];

export const EDUCATION_TYPES = [
  { value: "bachelors", text: "Bachelors" },
  { value: "masters", text: "Masters" },
  { value: "phd", text: "Ph. D." },
];

export const WORKPLACE_TYPES = [
  { value: "on_site", text: "On Site" },
  { value: "hybrid", text: "Hybrid" },
  { value: "remote", text: "Remote" },
];

export const JOB_TYPES = [
  { value: "contract", text: "Contract" },
  { value: "full_time", text: "Full Time" },
  { value: "part_time", text: "Part Time" },
  { value: "internship", text: "Internship" },
];

export const JOB_MIN_EXPERIENCES = [
  { value: "0 - 1 Years", text: "0 - 1 Years" },
  { value: "1 - 3 Years", text: "1 - 3 Years" },
  { value: "3 - 7 Years", text: "3 - 7 Years" },
  { value: "7 - 10 Years", text: "7 - 10 Years" },
  { value: "10 - 15 Years", text: "10 - 15 Years" },
  { value: "15+ Years", text: "15+ Years" },
];

export const TEAM_SIZE = [
  { value: "50-200", text: "50-200" },
  { value: "200+", text: "200+" },
  { value: "1000+", text: "1000+" },
  { value: "2000 - 5000", text: "2000 - 5000" },
  { value: "5000+", text: "5000+" },
];

export const INDUSTRY_TYPE = [
  { value: "investment_banking", text: "Investment Banking" },
  { value: "apparel_&_fashion", text: "Apparel & Fashion" },
  { value: "consumer_services", text: "Consmer Services" },
  { value: "media_production", text: "Media Production" },
  { value: "it", text: "IT" },
  { value: "package_delivery", text: "Package Delivery" },
];

export const JobFieldTitles = {
  prompt: "Prompt",
  job_description: "Description",
  job_title: "Job Title",
  job_status: "Status",
  job_expireIn: "Expiry Date",
  job_skills: "Skills",
  job_preferred_education: "Education",
  job_min_experience: "Work Experience",
  job_type: "Job Type",
  job_workplace_type: "Workplace",
};

export const DEFAULT_JOB_DETAILS = {
  prompt: null,
  job_description: null,
  job_title: "Working Title",
  job_status: "draft",
  job_expireIn: new Date(),
  job_skills: [],
  job_preferred_education: "bachelors",
  job_min_experience: "0 - 1 Year",
  job_type: "contract",
  job_workplace_type: "on_site",
};

export const HRJobFieldTitles = {
  prompt: "Prompt",
  job_description: "Description",
  job_title: "Job Title",
  job_status: "Status",
  job_expireIn: "Expiry Date",
  job_skills: "Skills",
  job_preferred_education: "Education",
  job_min_experience: "Work Experience",
  job_type: "Job Type",
  job_workplace_type: "Workplace",
  employer_id: "Select Employer",
  employer_attributes: "Select Employer",
};

export const DEFAULT_JOB_DETAILS_HR = {
  prompt: null,
  job_description: null,
  job_title: "Working Title",
  job_status: "draft",
  job_expireIn: new Date(),
  job_skills: [],
  job_preferred_education: "bachelors",
  job_min_experience: "0 - 1 Year",
  job_type: "contract",
  job_workplace_type: "on_site",
  employer_id: null,
  employer_attributes: {},
};

export const JobFormFields = [
  {
    id: "job_title",
    required: true,
    title: "Job Title",
    props: {
      type: CustomFieldTypes.TEXT,
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "job_status",
    required: true,
    title: "Job Status",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Status",
        options: STATUS_TYPES,
      },
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "job_min_experience",
    title: "Minimum Experience",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Min Exp",
        options: JOB_MIN_EXPERIENCES,
      },
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "job_preferred_education",
    title: "Preferred Education",
    required: false,
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Education",
        options: EDUCATION_TYPES,
      },
    },
  },
  {
    id: "job_workplace_type",
    required: true,
    title: "Workplace Type",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Workplace Type",
        options: WORKPLACE_TYPES,
      },
    },
  },
  {
    id: "job_type",
    required: true,
    title: "Job Type",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Job Type",
        options: JOB_TYPES,
      },
    },
  },
  {
    id: "job_expireIn",
    required: false,
    title: "End Date",
    props: {
      type: CustomFieldTypes.DATE,
    },
    validations: {
      date: true,
      required: true,
      //min: new Date(new Date().setDate(new Date().getDate() + 1))
    },
  },
  {
    id: "spacer",
    props: {
      type: CustomFieldTypes.EMPTY,
    },
  },
  {
    id: "job_skills",
    required: false,
    title: "Key Skills",
    props: { type: CustomFieldTypes.TAGS, max: 10 },
  },
  {
    id: "job_description",
    title: "Job Description",
    required: true,
    props: {
      type: CustomFieldTypes.TEXTAREA,
      width: "full",
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
];
export const CandidateJobViewFields = [
  {
    id: "job_title",
    required: true,
    title: "Job Title",
    props: {
      type: CustomFieldTypes.TEXT,
    },
  },
  {
    id: "job_status",
    required: true,
    title: "Job Status",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Status",
        options: STATUS_TYPES,
      },
    },
  },

  {
    id: "job_min_experience",
    title: "Minimum Experience",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Min Exp",
        options: JOB_MIN_EXPERIENCES,
      },
    },
  },
  {
    id: "job_preferred_education",
    title: "Preferred Education",
    required: false,
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Education",
        options: EDUCATION_TYPES,
      },
    },
  },
  {
    id: "job_workplace_type",
    required: true,
    title: "Workplace Type",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Workplace Type",
        options: WORKPLACE_TYPES,
      },
    },
  },
  {
    id: "job_type",
    required: true,
    title: "Job Type",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Job Type",
        options: JOB_TYPES,
      },
    },
  },
  {
    id: "job_skills",
    required: false,
    title: "Key Skills",
    props: { type: CustomFieldTypes.TAGS, max: 10 },
  },

  {
    id: "job_description",
    title: "Job Description",
    required: true,
    props: {
      type: CustomFieldTypes.TEXTAREA,
      width: "full",
    },
  },
];

export const CandidateJobFields = [
  {
    id: "job_info",
    required: false,
    title: "Job Information",
    props: {
      type: CustomFieldTypes.GROUP,
      width: "full",
      namespaced: false,
      isTransparent: true,
      dataEditable: false,
      headingField: "job_title",
      useTitle: false,
      cascade: false,
      hasNew: false,
      removable: false,
    },
    children: CandidateJobViewFields,
  },
];

export const NewJobValidations = {
  prompt: {
    boolean: ["string"],
    required: ["A ${path} is required to generate Job description"],
    min: [50, "${path} must be at least 50 Characters long."],
    max: [2950, "${path} must not be more than 250 Characters."]
  },
  job_description: {
    boolean: ["string", "required"],
  },
  job_title: {
    boolean: ["string"],
    required: ["${path} cannot be empty."],
  },
  job_status: null,
  job_expireIn: {
    boolean: ["date"],
    min: [new Date(), "${path} Must Occur In future"],
  },
  job_skills: {
    boolean: ["array"],
    min: [1, "${path} Can't Be Empty"],
  },
  job_preferred_education: null,
  job_min_experience: null,
  job_type: null,
  job_workplace_type: null,
};

export const HRNewJobValidations = {
  prompt: {
    boolean: ["string"],
    required: ["A ${path} is required to generate Job description"],
    min: [50, "${path} must be at least 50 Characters long."],
    max: [250, "${path} must not be more than 250 Characters."],
  },
  job_description: {
    boolean: ["string", "required"],
  },
  job_title: {
    boolean: ["string"],
    required: ["${path} cannot be empty."],
  },
  job_status: null,
  job_expireIn: {
    boolean: ["date"],
    min: [new Date(), "${path} Must Occur In future"],
  },
  job_skills: {
    boolean: ["array"],
    min: [1, "${path} Can't Be Empty"],
  },
  job_preferred_education: null,
  job_min_experience: null,
  job_type: null,
  job_workplace_type: null,
  employer_id: {
    boolean: ["string"],
    /* required: ["${path} cannot be empty."],
    nonNullable: ["${path} cannot be null."],*/
    when: [
      {
        target_field: "employer_attributes",
        is: (value) => !value || JSON.stringify(value) === JSON.stringify({}),
        then: (schema) =>
          schema
            .required("${path} cannot be empty.")
            .nonNullable("${path} cannot be null."),
        otherwise: (schema) => {
          console.log("Otherwise Schema : ", schema);
          return schema.nullable();
        },
      },
    ],
  },
  employer_attributes: {
    boolean: ["object"],
  },
};

export const DEFAULT_COMPANY_DETAILS = {
  description: null,
  name: null,
  website: null,
  email: null,
  tags: [],
  industry: null,
  phone_number: null,
  team_size: null,
};

export const CompanyFormFields = [
  {
    id: "name",
    required: true,
    title: "Company name",
    props: {
      type: CustomFieldTypes.TEXT,
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "industry",
    required: true,
    title: "Industry Type",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Industries",
        options: INDUSTRY_TYPE,
      },
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "email",
    title: "Company Email",
    props: {
      type: CustomFieldTypes.TEXT,
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "phone_number",
    title: "Phone Number",
    props: {
      type: CustomFieldTypes.TEXT,
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
      matches: [/^[0-9+\-\s()]*$/, "Please enter valid characters"],
      min: [10, "${path} can't be less than 10 characters"],
      max: [15, "${path} can't Be more than 15 characters"],
    },
  },
  {
    id: "city",
    title: "City",
    props: {
      type: CustomFieldTypes.TEXT,
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "team_size",
    required: true,
    title: "Company Size",
    props: {
      type: CustomFieldTypes.SELECT,
      selectProps: {
        firstOption: "Company Size",
        options: TEAM_SIZE,
      },
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
  {
    id: "tags",
    title: "Key Tags",
    props: { type: CustomFieldTypes.TAGS, max: 10 },
    validations: {
      array: true,
      min: 0,
      max: 10,
    },
  },
  {
    id: "description",
    title: "Description",
    required: true,
    props: {
      type: CustomFieldTypes.TEXTAREA,
      width: "full",
    },
    validations: {
      string: true,
      ensure: true,
      required: true,
    },
  },
];
